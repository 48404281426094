<template>
  <div class="m-2">
    <b-row>
      <b-col xl="12" md="12">
        <b-tabs content-class="pt-1" fill>
          <b-tab>
            <template #title>
              <!-- <feather-icon icon="HomeIcon" /> -->
              <span>คำสั่งซื้อ</span>
            </template>
            <div v-for="product in product" :key="product.id" no-body>
              <div>
                <b-card>
                  <div v-if="product.type === 'product'">
                    หมายเลขคำสั่งซื้อ: {{ product.omise_transactions_id }}
                    <div style="text-align: right">
                      <b-badge
                        variant="success"
                        style="padding: 10px"
                        v-if="product.status === 'successful'"
                      >
                        สั่งซื้อสำเร็จ
                      </b-badge>
                      <b-badge
                        variant="danger"
                        style="padding: 10px"
                        v-if="product.status === 'failed'"
                      >
                        ยกเลิกแล้ว
                      </b-badge>
                    </div>
                    <hr />
                    <div class="blue box ex2">
                      <div class="coral item">
                        <span class="pink item">
                          <div>
                            <b-img :src="product.image" width="120px" />
                            <span style="font-size: 17px">
                              {{ product.name }}
                            </span>
                            <div
                              style="font-size: 12px"
                              v-html="product.detail"
                            ></div>
                          </div>
                        </span>
                      </div>
                      <div class="coral item">
                        <span class="pink item">
                          <div>
                            <table class="mt-1">
                              <tr>
                                <th align="center">ราคาต่อหน่วย</th>

                                <th align="center">รายการย่อย</th>
                              </tr>
                              <br />
                              <tr>
                                <td align="center">
                                  {{
                                    new Intl.NumberFormat("en-IN").format(
                                      product.selling_price
                                    )
                                  }}
                                  บาท
                                </td>

                                <td align="center">
                                  จำนวน
                                  {{
                                    new Intl.NumberFormat("en-IN").format(
                                      product.quantity
                                    )
                                  }}
                                  ชิ้น
                                </td>
                              </tr>
                              <tr>
                                <td>ยอดคำสั่งซื้อทั้งหมด:</td>
                                <td>
                                  <code>
                                    {{
                                      new Intl.NumberFormat("en-IN").format(
                                        product.total_price
                                      )
                                    }}
                                    บาท
                                  </code>
                                </td>
                              </tr>
                            </table>

                            ช่องทางการชำระเงิน
                            <code>
                              {{ product.payment_options }}
                            </code>
                          </div>
                        </span>
                      </div>
                      <div class="coral item" style="width: 200px">
                        <span
                          class="pink item"
                          v-if="product.type === 'product'"
                        >
                          <div>
                            <span style="font-size: 13px">
                              <b>ที่อยู่ในการจัดส่ง</b> <br />
                              {{ product.user_address }}

                              || {{ product.phone }}<br />
                              ต. {{ product.district }} อ.
                              {{ product.amphoe }} จ. {{ product.province }} ,{{
                                product.zipcode
                              }}
                              ||
                              {{ product.detail_address }}
                              <hr />
                              <div v-if="product.status === 'successful'">
                                <b-alert
                                  variant="success"
                                  show
                                  v-if="product.delivery"
                                >
                                  <div class="alert-body">
                                    <span>{{ product.delivery_status }}</span>
                                  </div>
                                </b-alert>
                                <b-alert variant="warning" show v-else>
                                  <div class="alert-body">
                                    <span>{{ product.delivery_status }}</span>
                                  </div>
                                </b-alert>
                                {{ product.delivery }}
                              </div>
                            </span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else></div>
                </b-card>
              </div>
              <div></div>
            </div>
          </b-tab>
          <!-- <b-tab>
            <template #title>
            
              <span>สั่งซื้อสำเร็จ</span>
            </template>
            <div v-for="product in product" :key="product.id">
              <b-card v-if="product.status === 'successful'">
                <div v-if="product.type === 'product'">
                  หมายเลขคำสั่งซื้อ: {{ product.omise_transactions_id }}
                  <div style="text-align: right">
                    <b-badge
                      style="padding: 10px"
                      variant="success"
                      v-if="product.status === 'successful'"
                    >
                      สั่งซื้อสำเร็จ
                    </b-badge>
                  </div>
                  <hr />
                  <div class="blue box ex2">
                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <b-img :src="product.image" width="120px" />
                          <span style="font-size: 17px">
                            {{ product.name }}
                          </span>
                          <div
                            style="font-size: 12px"
                            v-html="product.detail"
                          ></div>
                        </div>
                      </span>
                    </div>
                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <table class="mt-1">
                            <tr>
                              <th align="center">ราคาต่อหน่วย</th>

                              <th align="center">รายการย่อย</th>
                            </tr>
                            <br />
                            <tr>
                              <td align="center">
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.price
                                  )
                                }}
                                บาท
                              </td>

                              <td align="center">
                                จำนวน
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.quantity
                                  )
                                }}
                                ชิ้น
                              </td>
                            </tr>
                            <tr>
                              <td>ยอดคำสั่งซื้อทั้งหมด:</td>
                              <td>
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.total_price
                                  )
                                }}
                                บาท
                              </td>
                            </tr>
                          </table>
                          ช่องทางการชำระเงิน
                          <code>
                            {{ product.payment_options }}
                          </code>
                        </div>
                      </span>
                    </div>
                    <div class="coral item" style="width: 200px">
                      <span class="pink item" v-if="product.type === 'product'">
                        <div>
                          <span style="font-size: 13px">
                            <b>ที่อยู่ในการจัดส่ง</b> <br />
                            {{ product.user_address }}

                            || {{ product.phone }}<br />
                            ต. {{ product.district }} อ. {{ product.amphoe }} จ.
                            {{ product.province }} ,{{ product.zipcode }} ||
                            {{ product.detail_address }}
                            <hr />
                            <div v-if="product.status === 'successful'">
                              <b-alert variant="primary" show>
                                <div class="alert-body">
                                  <span>{{ product.delivery_status }}</span>
                                </div>
                              </b-alert>

                              {{ product.delivery }}
                            </div>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else></div>
              </b-card>
            </div>
          </b-tab> -->
          <!-- <b-tab>
            <template #title>
  
              <span>ยกเลิกแล้ว</span>
            </template>
            <div v-for="product in product" :key="product.id">
              <b-card v-if="product.status === 'failed'">
                <div v-if="product.type === 'product'">
                  หมายเลขคำสั่งซื้อ: {{ product.omise_transactions_id }}
                  <div style="text-align: right">
                    <b-badge
                      style="padding: 10px"
                      variant="danger"
                      v-if="product.status === 'failed'"
                    >
                      ยกเลิกแล้ว
                    </b-badge>
                  </div>
                  <hr />
                  <div class="blue box ex2">
                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <b-img :src="product.image" width="120px" />
                          <span style="font-size: 17px">
                            {{ product.name }}
                          </span>
                          <div
                            style="font-size: 12px"
                            v-html="product.detail"
                          ></div>
                        </div>
                      </span>
                    </div>

                    <div class="coral item">
                      <span class="pink item">
                        <div>
                          <table class="mt-1">
                            <tr>
                              <th align="center">ราคาต่อหน่วย</th>

                              <th align="center">รายการย่อย</th>
                            </tr>
                            <br />
                            <tr>
                              <td align="center">
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.price
                                  )
                                }}
                                บาท
                              </td>

                              <td align="center">
                                จำนวน
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.quantity
                                  )
                                }}
                                ชิ้น
                              </td>
                            </tr>
                            <tr>
                              <td>ยอดคำสั่งซื้อทั้งหมด:</td>
                              <td>
                                {{
                                  new Intl.NumberFormat("en-IN").format(
                                    product.total_price
                                  )
                                }}
                                บาท
                              </td>
                            </tr>
                          </table>
                          ช่องทางการชำระเงิน
                          <code>
                            {{ product.payment_options }}
                          </code>
                        </div>
                      </span>
                    </div>
                    <div class="coral item" style="width: 200px">
                      <span class="pink item" v-if="product.type === 'product'">
                        <div>
                          <span style="font-size: 13px">
                            <b>ที่อยู่ในการจัดส่ง</b> <br />
                            {{ product.user_address }}

                            || {{ product.phone }}<br />
                            ต. {{ product.district }} อ. {{ product.amphoe }} จ.
                            {{ product.province }} ,{{ product.zipcode }} ||
                            {{ product.detail_address }}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else></div>
              </b-card>
            </div>
          </b-tab> -->
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import liff from "@line/liff";
import api from "@/api";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormSpinbutton,
  BLink,
  BBadge,
  BTabs,
  BTab,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    BFormSpinbutton,
    BLink,
    BBadge,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data_profile: "",
      lineID: "",
      name: "",
      email: "",
      picture: "",
      currentUrl: "",
      data: [],
      product: [],
    };
  },
  created() {
    if (
      localStorage.getItem("vuexy-skin") !== "light" ||
      !localStorage.getItem("vuexy-skin")
    ) {
      console.log("light :>> ");
      localStorage.setItem("vuexy-skin", "light");
      window.location.reload();
    }
    this.currentUrl = window.location.origin;
    if (this.$route.query.source) {
      this.get_transaction(this.$route.query.source);
    } else {
      liff
        .init({ liffId: "1657484988-oMMd0892" })
        .then(() => {
          console.log("then :>> ");
          console.log("liff.isLoggedIn() :>> ", liff.isLoggedIn());
          if (!liff.isLoggedIn()) {
            liff.login();
          } else {
            liff.getFriendship().then((data) => {
              if (data.friendFlag) {
                console.log("data.friendFlag :>> ", data.friendFlag);
                console.log("เปงงงงงกัง! เพื่อนกันยุแว้วว");
                this.data_profile = liff.getDecodedIDToken();
                console.log("this.data_profile :>> ", this.data_profile);
                this.lineID = this.data_profile.sub;
                this.name = this.data_profile.name;
                this.email = this.data_profile.email;
                this.picture = this.data_profile.picture;
                // alert(JSON.stringify(data));
                // alert(JSON.stringify( this.data_profile));
                localStorage.setItem(
                  "data_profile",
                  JSON.stringify(this.data_profile)
                );
                this.get_transaction(this.lineID);
              } else {
                console.log("data.friendFlag :>> ", data.friendFlag);
                // alert(JSON.stringify(data));
                var url =
                  "https://access.line.me/oauth2/v2.1/authorize?client_id=1657484988&response_type=code&redirect_uri=https://manager.socio.co.th/salse/order/sucess&logintype=web&scope=openid%20profile%20email&state=XXX&nonce=09876xyz&auth0&bot_prompt=aggressive&prompt=consent";
                console.log("url :>> ", url);
                window.location.href = url;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    if (this.$route.query.source) {
      this.$swal({
        title: "ชำระเงินสำเร็จ!",
        text: "สร้างรายการชำระสินค้าสำเร็จ!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    }
  },
  methods: {
    get_transaction(id) {
      const params = {
        source: id,
      };
      console.log("params :>> ", params);
      api.post("/get_transaction", params).then((response) => {
        this.product = response.data.sales_order_list;
        console.log(response.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
